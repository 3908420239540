import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { get } from '../../lib/nodash';
import { Box, Text } from 'grommet';

import SmartLink from '../SmartLink';
import { getProductPath, hasDarkTag } from '../../lib/product';

const BreadcrumbText = ({ children, dark, ...rest }) => (
  <Text
    size="xsmall"
    color={dark ? 'white' : 'brand'}
    style={{ letterSpacing: '1px' }}
    truncate={true}
    {...rest}
  >
    {children}
  </Text>
);
BreadcrumbText.propTypes = {
  children: PropTypes.node,
  dark: PropTypes.bool,
};

const ProductBreadcrumb = ({ product, collection, base }) => {
  const isDark = hasDarkTag(get('tags', product));
  const productPath = getProductPath(product);
  const collectionHandle = get('handle', collection);
  const showBase = base && get('slug', base) !== get('handle', collection);

  return (
    <Box direction="row" gap="small" background={{ dark: isDark }}>
      {showBase && (
        <BreadcrumbText>
          <SmartLink plain to={`/${base.slug}`}>
            {get('title', base)}
          </SmartLink>
        </BreadcrumbText>
      )}
      {showBase && <BreadcrumbText dark={isDark}>/</BreadcrumbText>}
      {collectionHandle && (
        <BreadcrumbText>
          <SmartLink
            plain
            to={`/${
              collectionHandle.indexOf('collections/') > -1
                ? collectionHandle
                : `collections/${collectionHandle}`
            }`}
          >
            {get('title', collection)}
          </SmartLink>
        </BreadcrumbText>
      )}
      {collectionHandle && <BreadcrumbText dark={isDark}>/</BreadcrumbText>}
      <BreadcrumbText weight={500}>
        <SmartLink plain to={productPath} weight={500}>
          {product.title}
        </SmartLink>
      </BreadcrumbText>
    </Box>
  );
};

ProductBreadcrumb.propTypes = {
  product: PropTypes.object.isRequired,
  collection: PropTypes.object,
  base: PropTypes.object,
};

export default memo(ProductBreadcrumb);
