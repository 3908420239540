import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { Box } from 'grommet';

import ProductBreadcrumb from './ProductBreadcrumb';

const ProductBreadcrumbHero = ({ product, collection, base }) => {
  const location = useLocation();
  const baseCollection = collection
    ? collection
    : location.state
    ? location.state.collection
    : undefined;

  return (
    <Box
      pad={'medium'}
      style={{
        position: 'absolute',
        top: `0`,
        left: 0,
        zIndex: 10,
      }}
    >
      <ProductBreadcrumb
        collection={baseCollection}
        product={product}
        base={base}
      />
    </Box>
  );
};

ProductBreadcrumbHero.propTypes = {
  product: PropTypes.object.isRequired,
  collection: PropTypes.object,
  base: PropTypes.object,
};

export default memo(ProductBreadcrumbHero);
